@font-face {
    font-family: "Kanit";
    src: url("../fonts/Kanit/Kanit-Regular.ttf");
    font-style: "normal";
    font-weight: 400;
}

@font-face {
    font-family: "Kanit";
    src: url("../fonts/Kanit/Kanit-Medium.ttf");
    font-style: "normal";
    font-weight: 500;
}

@font-face {
    font-family: "Kanit";
    src: url("../fonts/Kanit/Kanit-SemiBold.ttf");
    font-style: "normal";
    font-weight: 600;
}

@font-face {
    font-family: "Kanit";
    src: url("../fonts/Kanit/Kanit-Bold.ttf");
    font-style: "normal";
    font-weight: 700;
}