* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Kanit", Arial, Helvetica, sans-serif;
}

.privilege,
.privilege .slick-slide {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 230px;
}

.privilege {
    position: relative;
}

.privilege .slick-slide img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.privilege .slick-dots.slick-dots-bottom {
    bottom: 20px;
}

.privilege .slick-list {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

@media screen and (max-width: 576px) {
    .ant-picker-panels {
        display: flex;
        flex-direction: column;
    }
}

.member .swiper-pagination-bullet {
    background: #A9A9A9;
    opacity: 1;
}

.member .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #004559;
}